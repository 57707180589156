import React, { Component } from "react"
import { grpc } from "@improbable-eng/grpc-web"
// @ts-ignore
import { stringify as uuidStringify } from "uuid"
import { Token } from "api-specification/xdefi_pb"
import { Empty, Pong } from "api-specification/gateway_v000_pb"
import { GatewayClient as GatewayClient000 } from "api-specification/gateway_v000_pb_service"
import { GatewayClient as GatewayClient001 } from "api-specification/gateway_v001_pb_service"
import { GetAssetsRequest, GetAssetsResponse, GetPricesRequest, GetPricesResponse } from "api-specification/gateway_v001_pb"

const GRPC_API_URL = process.env.REACT_APP_GRPC_API_URL as string
// const GRPC_API_URL = "http://localhost:8080"
const gateway_000_client = new GatewayClient000(GRPC_API_URL)
const gateway_001_client = new GatewayClient001(GRPC_API_URL)

const tokens_by_address: { [chain_name: string]: { [location: string]: Token } } = {}
const tokens_by_symbol: { [symbol: string]: Token[] } = {}

class MainContainer extends Component<{}, { assetSymbolInput: string, chainNameInput: string, locationInput: string }> {
  constructor(props: any) {
    super(props);
    this.state = {
      assetSymbolInput: '',
      chainNameInput: '',
      locationInput: ''
    }
  }

  _onPing = () => {
      console.log("Starting PING")
      return gateway_000_client
        .ping(new Empty())
        .on("data", (data: Pong) => console.log("data", data))
        .on("end", (status: any) => console.log("end", status))
        .on("status", (status: any) => {
          console.log("status", status)
          this._onPing()
        })
  }

  _onGetAssets = () => {
    console.log("Requesting Assets")
    var startTime = performance.now()
    return gateway_001_client.getAssets(new GetAssetsRequest(), new grpc.Metadata(), (err, response) => {
      console.log('Response in', (performance.now() - startTime) / 1000, 'seconds')
      console.log({ err, response: GetAssetsResponse })
      if (response) {
        console.log('Fiat count:', response.getCurrenciesList().length)
        console.log('Crypto count:', response.getCryptosList().length)
        console.log('Token count:', response.getTokensList().length)

        this._indexTokens(response)
      }
    })
  }

  _getPrices = (assetIDs: (string | Uint8Array)[]) => {
    console.log("Requesting Prices")
    var request = new GetPricesRequest()
    request.setAssetIdsList(assetIDs)
    return gateway_001_client
      .getPrices(request)
      .on("data", (data: GetPricesResponse) => {
        for (var price of data.getPricesList())
          console.log(uuidStringify(price.getAssetId()), price.getPrice()?.getValue())
      })
      .on("end", (status: any) => console.log("end", status))
      .on("status", (status: any) => {
        console.log("status", status)
        this._getPrices(assetIDs)
      })
  }

  _indexTokens = (response: GetAssetsResponse) => {
    for (var token of response.getTokensList()) {
      var symbol: string = token.getSymbol()
      if (!(token.getSymbol() in tokens_by_symbol))
        tokens_by_symbol[symbol] = []
      tokens_by_symbol[symbol].push(token)

      for (var token_contract of token.getContractsList()) {
        var chainName = token_contract.getAddress()?.getChain()?.getName()
        var location = token_contract.getAddress()?.getLocation()
        if (chainName && location) {
          if (!(chainName in tokens_by_address))
            tokens_by_address[chainName] = {}
          tokens_by_address[chainName][location.toLowerCase()] = token
        }
      }
    }
    console.log('Finished indexing tokens')
  }

  _handleAssetSymbolChange = (event: any) => {
    this.setState({assetSymbolInput: event.target.value});
  }

  _handleChainNameChange = (event: any) => {
    this.setState({chainNameInput: event.target.value});
  }

  _handleLocationChange = (event: any) => {
    this.setState({locationInput: event.target.value});
  }

  _onStreamPricesBySymbol = () => {
    var tokenIDs = []
    for (var token of tokens_by_symbol[this.state.assetSymbolInput])
      tokenIDs.push(token.getAssetId())
    console.log('Asset IDs:', tokenIDs)
    this._getPrices(tokenIDs)
  }

  _onStreamPricesByAddress = () => {
    var tokenID = tokens_by_address[this.state.chainNameInput][this.state.locationInput.toLowerCase()].getAssetId()
    console.log('Asset ID:', tokenID)
    this._getPrices([tokenID])
  }

  render() {
    return(
      <div>
        <button onClick={this._onPing} style={{ fontSize: 30 }}>
          Ping
        </button>
        <button onClick={this._onGetAssets} style={{ fontSize: 30 }}>
          Get Assets
        </button>

        <p>Get price feed:</p>
        <p>By symbol: <input value={this.state.assetSymbolInput} onChange={this._handleAssetSymbolChange}/>
          <button onClick={this._onStreamPricesBySymbol}>Stream</button>
        </p>
        <p>By address:
          chain name: <input value={this.state.chainNameInput} onChange={this._handleChainNameChange}/>
          location: <input value={this.state.locationInput} onChange={this._handleLocationChange}/>
          <button onClick={this._onStreamPricesByAddress}>Stream</button>
        </p>
      </div>
    )
  }
}

function App() {
  return (
    <div>
      <MainContainer />
    </div>
  )
}

export default App
